import axios from '@axios';
import qs from 'qs';
import _ from 'lodash';

const scheduleInitialQuery = () => {
    return {
        sort: ['id:asc'],
        filters: {
            stop: {
                id: {
                    $containsi: '',
                },
            },

            departure_time: {
                $containsi: '',
            },

            arrival_time: {
                $containsi: '',
            },

            trip: {
                route: {
                    id: {
                        $containsi: '',
                    },
                },
            },

            $or: [
                {
                    stop: {
                        name: {
                            $containsi: '',
                        },
                    },
                },
                {
                    trip: {
                        transport: {
                            name: {
                                $containsi: '',
                            },
                        },
                    },
                },
                {
                    trip: {
                        route: {
                            long_name: {
                                $containsi: '',
                            },
                        },
                    },
                },
                {
                    departure_time: {
                        $containsi: '',
                    },
                },
            ],
        },

        populate: ['trip', 'trip.route', 'trip.transport', 'stop'],

        pagination: {
            page: 1,
            pageSize: 25,
        },
    };
};

export default {
    namespaced: true,
    state: {
        schedules: [],
        scheduleInitialQuery: scheduleInitialQuery(),
        isDeleteSchedule: false,
    },

    getters: {
        getSchedulesList(state) {
            return state.schedules;
        },
        getSchedulesQuery(state) {
            return state.scheduleInitialQuery;
        },
        getSchedule(state) {
            return state.schedules;
        },
        getIsDeleteSchedule(state) {
            return state.isDeleteSchedule;
        },
    },
    mutations: {
        setSchedulesList(state, schedules) {
            state.schedules = schedules;
        },
        setSchedulesQuery(state, query) {
            state.scheduleInitialQuery = query;
        },
        resetSchedulesQuery(state) {
            state.scheduleInitialQuery = scheduleInitialQuery();
        },
        setSchedule(state, scheduleData) {
            state.routeData = scheduleData;
        },
        setScheduleSearch(state, search) {
            state.scheduleInitialQuery.filters.$or[0].stop.name.$containsi = search;
            state.scheduleInitialQuery.filters.$or[1].trip.transport.name.$containsi = search;
            state.scheduleInitialQuery.filters.$or[2].trip.route.long_name.$containsi = search;
            state.scheduleInitialQuery.filters.$or[3].departure_time.$containsi = search;
        },

        setIsDeleteSchedule(state, bol) {
            state.isDeleteSchedule = bol;
        },
    },

    actions: {
        fetchScheduleList(ctx) {
            let _query = _.cloneDeep(ctx.state.scheduleInitialQuery);

            let query = qs.stringify(_query);

            return new Promise((resolve, reject) => {
                axios
                    .get('/mobility-stop-times?' + query)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        deleteManySchedules(ctx, query = {}) {
            query = qs.stringify(query);
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/mobility-stop-times/many?${query}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        /*    fetchRoute(ctx, id, query = {}) {
            query.populate = ['agency'];
            return new Promise((resolve, reject) => {
                axios
                    .get(`/mobility-routes/${id}`, { params: query })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        }, */
    },
};
